import { render, staticRenderFns } from "./MemberMsgAdd.vue?vue&type=template&id=2efb74bc&scoped=true&"
import script from "./MemberMsgAdd.vue?vue&type=script&lang=js&"
export * from "./MemberMsgAdd.vue?vue&type=script&lang=js&"
import style0 from "./MemberMsgAdd.vue?vue&type=style&index=0&id=2efb74bc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2efb74bc",
  null
  
)

export default component.exports