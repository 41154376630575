<template>
    <div class="article-box" v-loading="loading">
        <div class="page-nav clearfix">
            <ul>
                <li class = "nav-item">
                    会员管理&nbsp;&nbsp;/
                </li>
                <li>
                    <router-link class="nav-item" to="/institution/member/msg">
                        &nbsp;&nbsp;消息发送&nbsp;&nbsp;/
                    </router-link>
                </li>
                <li class = "nav-item nav-item-last">
                    &nbsp;&nbsp;添加新消息
                </li>
            </ul>
        </div>
        <!-- 标题 -->
        <div class="pag-title clearfix">
            <div class="page-title-name pull-left">添加新消息</div>
        </div>
        <div class="form-box">
          <el-form ref="form" label-width="100px" :rules="rules" :model="formData">
            <el-form-item label="消息标题" prop="msgTitle">
                <el-input v-model="formData.msgTitle" style="width: 280px" size="small" ></el-input>
            </el-form-item>
          <el-form-item label="发送时间:">
            <el-date-picker
              style="width: 280px;"
              v-model="msgTimer"
              type="datetime"
              placeholder="选择日期时间"
              default-time="12:00:00">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="推送人员" prop="userType">
            <el-radio-group v-model="formData.userType" @change="userTypeChange">
              <el-radio label="1">按角色</el-radio>
              <el-radio label="2">按等级</el-radio>
              <el-radio label="3">按用户</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label=" ">
            <el-checkbox-group v-model="roleList" v-if="formData.userType == 1">
              <el-checkbox label="ROLE_personal_member" key="ROLE_personal_member">个人会员</el-checkbox>
              <el-checkbox label="ROLE_enterprise_member" key="ROLE_enterprise_member ">单位会员</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label=" ">
            <el-checkbox-group v-model="levelList"  v-if="formData.userType == 2">
              <el-checkbox label="1" key="1">会员</el-checkbox>
              <el-checkbox label="2" key="2 ">理事</el-checkbox>
              <el-checkbox label="3" key="3">常务理事</el-checkbox>
              <el-checkbox label="4" key="4 ">副会长</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
            <el-form-item label=" " v-if="formData.userType == 3">
              <el-input v-model="searchKey" style="width: 280px" size="small"></el-input>
              <el-button type="primary" style="margin-left: 20px" size="small" @click="searchUser()">查询</el-button>
            </el-form-item>
            <el-form-item label=" "  v-if="formData.userType == 3">
                <el-table
                  v-loading="tableLoading"
                  ref="userList"
                  :data="userList"
                  highlight-current-row
                  max-height="300"
                   @selection-change="handleSelectionChange"
                  style="width: 100%">
                  <el-table-column
                    type="selection"
                    width="55">
                  </el-table-column>
                  <el-table-column
                    property="loginName"
                    label="账号"
                    width="120">
                  </el-table-column>
                  <el-table-column
                    property="unitName"
                    label="单位全称"
                    width="120">
                  </el-table-column>
                  <el-table-column
                    property="phone"
                    label="绑定手机号">
                  </el-table-column>
                  <el-table-column
                    property="email"
                    label="绑定邮箱">
                  </el-table-column>
                  <el-table-column
                    label="会员等级">
                    <template slot-scope="scope">
                      {{showOrgLevel(scope.row.orgLevel)}}
                    </template>
                  </el-table-column>
                </el-table>
            </el-form-item>
            <el-form-item label="已选用户" v-if="formData.userType == 3">
              <el-tag
                closable
                style="margin-right:10px"
                v-for="(item, index) in senduserList"
                :key="item.id"
                @close="delUser(index)">
                {{item.loginName}}
              </el-tag>
            </el-form-item>
            <el-form-item label="消息内容"  prop="msgContent">
              <el-input type="textarea" v-model="formData.msgContent"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div style="margin: 20px" class="clearfix">
          <div class="button-item button-search pull-right" @click="sureHandler()"  style="margin-left: 20px">确定</div>
          <div class="button-item button-cancle pull-right" @click="cancelHandler()">取消</div>
        </div>
    </div>
</template>
<script>
import {
  MermberMsgAdd,
  MermberMsgUser,
} from "@/http/api/InitiationApi";
import {showOrgLevel} from '@/utils/util'

export default {
  name: "",
  props: {},
  components: {},
  data () {
    return {
      loading: false,
      searchKey: '',
      userType: '',
      userList: [],
      msgContent: '',
      tableLoading: false,
      
      msgTitle: '',
      msgTimer: '',
      roleList: [],
      levelList: [],
      senduserList: [],
      // :model="ruleForm"
      formData: {
        msgTitle: '',
        userType: '',
        msgContent: ''
      },
      rules: {
        msgTitle: [{ required: true, message: '必填', trigger: 'blur' }],
        userType: [{ required: true, message: '必填', trigger: 'change' }],
        msgContent: [{ required: true, message: '必填', trigger: 'blur' }]
      }
    };
  },
  computed: {},
  created() {
    this.isShowTable = false
  },
  methods: {
   userTypeChange (val) {
      this.roleList = [];
      this.levelList = [];
      this.userList = [];
      if (val == 3) {
        this.tableLoading = true;
        if (!this.userList.length) {
            MermberMsgUser({
              pageNo: 1,
              pageSize: 10000,
              nickName: this.searchKey
            }).then(resp => {
              this.tableLoading = false ;
              if (resp.code == '000') {
                this.userList = resp.result.records;
              } else {
                this.userList = [];
              }
            })
        }
      }
   },
   handleSelectionChange (val) {
     this.senduserList = [...val]
   },
   searchUser () {
     MermberMsgUser({
        pageNo: 1,
        pageSize: 10000,
        nickName: this.searchKey
      }).then(resp => {
        this.tableLoading = false ;
        if (resp.code == '000') {
          this.userList = resp.result.records;
        } else {
          this.userList = [];
        }
      })
   },
   delUser (index) {
     this.$refs.userList.toggleRowSelection(this.senduserList[index]);
   },
   sureHandler () {
      let item = {}
      item.title = this.formData.msgTitle;
      if (this.msgTimer) {
        item.sendTimesTamp = Math.floor(new Date(this.msgTimer).getTime()/1000);
      }
      // console.log(item)
      // return false
      item.type = [];
      item.content = this.formData.msgContent;
      if (this.formData.userType == 1) {
        item.roleCodes = this.roleList.join(',')
      } else if (this.formData.userType ==2) {
        item.tags = this.levelList.join(',')
      } else if (this.formData.userType == 3) {
        item.userList = this.senduserList.map(s => {
          return {id: s.id, mobile: s.mobile, email:s.email}
        })
      }

      if (this.msgTimer) {
        if (item.sendTimesTamp - Math.floor(new Date().getTime()/1000) < 300) {
           this.$message({
              message: '发送时间应大于当前时间5分钟',
              type: 'error'
            });
            return
        }
      }

      this.loading = true;
      MermberMsgAdd(item).then(resp => {
          this.loading = false
          if (resp.code == '000') {
            this.$message({
                message: '操作成功',
                type: 'success'
              });
              setTimeout(()=>{
                this.$router.go(-1)
              },1000)
          } else {
            this.$message({
                message: '操作失败',
                type: 'error'
            })
          }
       })
   },
   cancelHandler () {
   },
   showOrgLevel (val) {
     return showOrgLevel(val)
   }
  },
};
</script>
<style lang='less' scoped>
.form-box {
  padding-top: 30px;
}
/deep/.el-form-item {
    margin-bottom: 5px;
}
</style>