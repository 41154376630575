/**
 *  验证手机号码   value: 手机号码
 */
 export const isPhone = (value: any): boolean => {
  if (value === '' || value === undefined || value === null) {
    return false;
  }
  const pattern = /^[1][3,4,5,7,8,9][0-9]{9}$/;
  return pattern.test(value);
};

/**
 *  验证密码格式(8-16位数字字母组合)   value: 密码
 */
 export const isPassword = (value: any): boolean => {
  if (value === '' || value === undefined || value === null) {
    return false;
  }
  const pattern = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;
  return pattern.test(value);
};

/**
 * 验证邮箱    val: 邮箱
 */
 export const validateEMail = (val: any) => {
  if (val === '' || val === undefined || val === null) {
    return false;
  }
  // eslint-disable-next-line no-useless-escape
  const pattern = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
  return pattern.test(val);
};

// 显示函数
//大会等级 1.会员 2.理事 3.常务理事 4.副会长 5.会员代表 ,
export const showPlenaryLevel = (val: string | number) => {
    switch (val) {
        case '1' || 1:
            return '会员';
        case '2' || 2:
            return '理事';
          case '3' || 3:
            return '常务理事';  
        case '4' || 4:
            return '副会长';
        case '5' || 5:
            return '会员代表';   
        default:
            return '-';           
      }
  };
// 本机构等级 1.会员 2.理事 3.常务理事 4.副会长 ,
export const showOrgLevel = (val: string | number) => {
    switch (val) {
        case '1' || 1:
            return '会员';
        case '2' || 2:
            return '理事';
          case '3' || 3:
            return '常务理事';  
        case '4' || 4:
            return '副会长';  
        default:
            return '-';           
    }
};
// 单位性质 unitNature 1.国企 2.事业 3.合资 4.民营 5.社团 6.其他 ,
export const showUnitNature = (val: string | number) => {
    switch (val) {
        case '1' || 1:
            return '国企';
            break;
        case '2' || 2:
            return '事业';
            break;
        case '3' || 3:
            return '合资';
            break;  
        case '4' || 4:
            return '民营';
            break;
        case '5' || 5:
            return '社团';
            break;  
        case '6' || 6:
            return '其他';
            break;    
        default:
            return '-';           
    }
};
// 行业性质 industryNature  1.桥梁工程 2.隧道工程 3.基础设施 4.装备技术 5.运输综合 6.物流 ,
export const showIndustryNature = (val: string | number) => {
  switch (val) {
      case '1' || 1:
          return '桥梁工程';
      case '2' || 2:
          return '隧道工程';
        case '3' || 3:
          return '基础设施';  
      case '4' || 4:
          return '装备技术';
      case '5' || 5:
          return '运输综合';  
      case '6' || 6:
          return '物流';    
      case '7' || 7:
          return '其他';   
      default:
          return '-';           
  }
};
// 性别 sex 1.男 2.女 ,
export const showSex = (val: string | number) => {
  switch (val) {
      case '1' || 1:
          return '男';
          break;
      case '2' || 2:
          return '女';
          break; 
      default:
          return '-';           
  }
}
